<template>
    <DelayHydration>
        <footer
            id="footer-landmark"
            class="footer layout__footer"
        >
            <section class="footer__section footer__navigation">
                <TheFooterList
                    v-for="(list, index) in footerLists"
                    :key="index"
                    class="footer__list"
                    :items="list.items"
                    :title="list.title"
                />

                <BaseAccordion
                    class="footer__accordion"
                    :items="footerLists"
                >
                    <template #default="{ item }">
                        <TheFooterLink
                            v-for="(link, linkIndex) in item.items"
                            :key="linkIndex"
                            :item="link"
                        />
                    </template>
                </BaseAccordion>
            </section>
            <section class="footer__section footer__logos">
                <img
                    :src="footerLogoUrl"
                    :alt="siteName"
                >

                <BaseSocials class="footer__socials" />
            </section>
            <section class="footer__section footer__legal" v-if="subfooterLinks">
                <nuxt-link
                    v-for="(link, linkIndex) in subfooterLinks"
                    :key="linkIndex"
                    :to="getItemLink(link)"
                >
                    {{ link.linkTitle }}
                </nuxt-link>
            </section>
        </footer>
    </DelayHydration>
</template>

<script setup>
import FooterQuery from '~/graphql/queries/global/Footer.graphql';

const siteName = inject('siteName');
const footerLogoUrl = inject('footerLogoUrl');
const { variables } = useCraftVariables();

const { data } = await useLazyAsyncQuery({
    query: FooterQuery,
    variables
});

const footerData = computed(() => data?.value?.footer?.footer ?? []);

const footerLists = computed(() => { 
    if (data?.value?.footer?.contactAddress) {
        let contactButton = {};

        if (data?.value?.footer?.contactLink && data?.value?.footer?.contactLink.length) {
            contactButton = { text: 'Contact us', type: 'button', uri: data?.value?.footer?.contactLink[0].uri };
        }

        return [{
            title: 'Contact',
            items: [
                { html: data?.value?.footer?.contactAddress, type: 'html' },
                contactButton
            ]
        }];
    }

    return [];
});

const subfooterLinks = computed(() => {
    if (data?.value?.footer?.subfooterLinks && data?.value?.footer?.subfooterLinks.length) {
        return data?.value?.footer?.subfooterLinks;
    }

    return false;
});

watchEffect(() => {
    if (footerData.value) {
        const lists = [];

        footerData.value.forEach((column) => {
            lists.push({
                title: column.blockTitle,
                items: getItems.call(this, column)
            });
        });

        // Prepend lists to existing (hardcoded comes last)
        footerLists.value.unshift(...lists);
    }
});

function getItems(column) {
    const items = [];

    column.children?.[0]?.lists?.forEach((item) => {
        const uri = getItemLink(item);
        items.push({
            text: item.linkTitle,
            type: uri ? 'link' : 'text',
            uri
        });
    });

    return items;
}

function getItemLink(item) {
    return item.linkInternal?.length ? `/${item.linkInternal[0].uri}` : item.linkExternal;
}
</script>

<style lang="less" src="./TheFooter.less" />
